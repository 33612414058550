<template>
  <div>
        <div class="row align-items-center">
            <div class="col-md-10">
                <span class="mt-2" style="color: red;"><strong>Es ist KEINE Pflicht einem Shop auch eine Kategorie zu zuweisen. Allerdings sind die Artikel nur in dem entsprechenden Shop sichtbar, wenn hier auch eine Kategorie zugwiesen wurde</strong></span>
            </div>
            <div class="col-md-2">
                <b-button class="mt-2" size="sm" @click="saveCategories()" variant="primary"><i class="fas fa-save"></i> Speichern</b-button>
            </div>
        </div>
        <div class="row">

            <div class="col-md-6" v-for="company in companies" :key="company.id">
                <fieldset class="p-2 mt-3 border">
                    <legend class="w-auto">{{company.name}}</legend>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <select multiple="multiple" class="duallistbox" style="display: none;" :name="'categories_' + company.prefix" v-model="article.categories">
                                    <option v-for="category in company.treeview" :key="category.id" :value="category.id">{{category.text}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>

        </div>
        
  </div>
</template>

<script>
import $ from 'jquery'
import 'bootstrap4-duallistbox/dist/jquery.bootstrap-duallistbox.min.js';

export default {
    props: [
        'article',
        'companies'
    ],

    data(){
        return{
            
        }
    },

    methods:{
        saveCategories(){
            var selCat = [];
            this.companies.forEach((value) => {
            var ids = $('select[name="categories_'+ value.prefix +'"]').val();     
                ids.forEach((item) => {
                    selCat.push(parseInt(item));
                });
            });
            
            this.axios
                .post("/articles/setCategories", {
                    'article_id': this.article.id,
                    'categories': selCat,
                })
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Änderungen gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$parent.loadArticle();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

    },

    mounted(){
        // $('.duallistbox').bootstrapDualListbox();
        this.companies.forEach((value) => {
            $('select[name="categories_'+ value.prefix +'"]').bootstrapDualListbox({
                nonSelectedListLabel: 'Verfügbar',
                selectedListLabel: 'Zugewiesen',
                infoText: false,
                infoTextFiltered: '{0} bis {1}',

            });
        });
        
    }
}
</script>

<style>

</style>